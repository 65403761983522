<template>
  <form v-on:submit.prevent="enviar">
    <div class="mt-4 form-table w-full md:max-w-screen-md text-sm">
      <div class="row">
        <div>Cliente:</div>
        <div class="value relative">
          <c-select
            v-if="!editMode && !aviso.clienteStr"
            class="flex justify-start w-2/3"
            v-model:selected="aviso.cliente"
            :id="'cliente'"
            :options="clients"
            :fullWidth="true"
            :disabled="disabled"
          ></c-select>
          <span v-else>
            {{ aviso.clienteStr }}
          </span>
        </div>
      </div>

      <div class="row" v-if="!editMode">
        <div>Fecha:</div>
        <div class="value">{{ fechaHoy }}</div>
      </div>

      <div class="row date">
        <div>Fecha del pago:</div>
        <div class="value">
          <datepicker
            v-if="!editMode"
            v-model:value="aviso.fechaPago"
            class="rounded border px-1 w-24"
            :disabled="disabled"
          />
          <span v-else>
            {{ aviso.fechaPago ? format(aviso.fechaPago, "dd/MM/yyyy") : "" }}
          </span>
        </div>
      </div>

      <div class="row" v-if="editMode">
        <div>Estado:</div>
        <div class="value relative">
          <c-select
            v-if="editMode"
            v-model:selected="aviso.estado"
            :id="'status'"
            :options="estados"
            :fullWidth="true"
            :canSearch="false"
            :disabled="disabled"
            class="flex justify-start w-2/3"
          ></c-select>
        </div>
      </div>

      <div class="row">
        <div>Comentarios:</div>
        <div class="value">
          <textarea
            v-if="!editMode"
            v-model="aviso.comentarios"
            :disabled="disabled"
            class="w-full lg:w-2/3 border rounded px-1"
            placeholder="Aclare lo que considere necesario para la imputación del pago a los comprobantes asignados"
          >
          </textarea>
          <span v-else>
            {{ aviso.comentarios }}
          </span>
        </div>
      </div>

      <div class="row">
        <div>Tipo de cambio:</div>
        <div class="value">
          <input-number :disabled="disabled" v-model:value="aviso.tipoCambio">
          </input-number>
        </div>
      </div>

      <div class="row">
        <div>Importe pagado:</div>
        <div class="value">
          <input-usd :disabled="disabled" v-model:value="totalPagado">
          </input-usd>
        </div>
      </div>

      <div class="row">
        <div>Total comprobantes:</div>
        <div class="value">
          <input-usd :disabled="disabled" v-model:value="totalComprobantes">
          </input-usd>
        </div>
      </div>

      <div class="row">
        <div>Importe restante:</div>
        <div :class="[{ 'text-red-600': importeRestante < 0 }, 'value']">
          <input-usd :disabled="disabled" v-model:value="importeRestante">
          </input-usd>
        </div>
      </div>

      <!-- Formas de pago -->
      <template v-for="formaPago in formasDePago" :key="formaPago.id">
        <div
          class="row"
          v-for="(item, index) in aviso[formaPago.id]"
          :key="index"
        >
          <form-pago
            :titulo="`${formaPago.texto} ${index + 1}`"
            :tipo="item.tipo"
            v-model:importe="item.importe"
            v-model:moneda="item.moneda"
            v-model:numero="item.numero"
            v-model:emision="item.emision"
            v-model:pago="item.pago"
            v-model:agencia="item.agencia"
            v-model:banco="item.banco"
            v-model:sucursal="item.sucursal"
            v-model:file="item.file"
            :bancos="bancos"
            :sucursales="sucursales"
            :agencias="agencias"
            :monedas="monedas"
            :readOnly="disabled"
            @borrar="removeIndex(formaPago.id, index)"
          >
          </form-pago>
        </div>
      </template>

      <!-- Formas de pago -->
      <div class="my-4 flex flex-wrap gap-y-2 gap-x-2">
        <button-blue
          v-for="item in formasDePago"
          :key="item.id"
          :disabled="disabled"
          @click="addPago(item.id)"
        >
          {{ item.texto.toUpperCase() }}
        </button-blue>
      </div>

      <!-- Comprobantes a imputar -->
      <div class="row w-full">
        <h1 class="col-span-5 mb-4">Comprobantes a imputar:</h1>

        <!-- Descarga de comprobantes -->
        <a id="descargaComprobante" href="" hidden></a>

        <div class="col-span-5">
          <div v-if="comprobantes.length === 0 && !aviso.cliente">
            Seleccione un cliente para ver sus comprobantes
          </div>
          <div
            v-else-if="
              comprobantes.length === 0 && aviso.cliente && !loadingComprobantes
            "
          >
            No tiene comprobantes pendientes de pago
          </div>
          <div v-else>
            <div class="flex w-16">
              <c-select
                :id="'moneda'"
                :options="monedas"
                :selected="monedaActiva"
                :can-search="false"
                @update:selected="changeMonedaActiva"
              ></c-select>
            </div>
            <div
              v-for="(comprobante, index) in comprobantesComputed"
              :key="index"
              class="flex items-center gap-x-2 px-2 py-4 hover:bg-gray-100"
            >
              <input
                type="checkbox"
                name="comprobantes"
                v-model="comprobantesSelected"
                :value="comprobante.documentId"
                class="h-6 w-6 cursor-pointer"
                :disabled="disabled"
              />
              <div class="flex-1 flex-wrap">
                {{ comprobante.text }}
              </div>

              <a @click="descargarComprobante(comprobante.url)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="
                    h-5
                    w-5
                    hover:text-main-blue
                    transition
                    duration-300
                    cursor-pointer
                  "
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                    clip-rule="evenodd"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-2 p-2 font-semibold text-xs">
        Adjunte orden de pago y/o depósito bancario y/o retenciones en caso de
        corresponder
      </div>

      <div class="w-full mt-2 flex justify-end items-center gap-x-3">
        <div
          :class="[
            'flex items-center cursor-pointer',
            { 'text-gray-400': disabled, 'text-secondary-blue': !disabled },
          ]"
          @click="goBack"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-3 w-3"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
              clip-rule="evenodd"
            />
          </svg>
          Cancelar
        </div>

        <button-blue type="submit" :disabled="disabled">
          {{ editMode ? "GUARDAR" : "ENVIAR" }}
        </button-blue>
      </div>
    </div>
  </form>
</template>

<script>
import { computed, defineComponent, reactive, ref, toRef, watch } from "vue";
import { format, parseISO, parse } from "date-fns";
import {
  apiPaymentMethods,
  apiGetAgencias,
  apiGetDocuments,
  apiGetFilters,
  apiClientPayments,
  apiGetDocumentsFromForm,
  apiDescargarSige,
} from "@/router/endpoints";
import { formatBancos, getBancoId } from "@/FormatFeli";
import { showError, stringToDate } from "@/Functions";
import axios from "axios";
import router from "@/router";
import { useStore } from "vuex";

import Datepicker from "@/components/Datepicker.vue";
import InputUsd from "@/components/PanelAvisosNuevoInput.vue";
import CSelect from "./Select.vue";
import ButtonBlue from "./ButtonBlue.vue";
import InputNumber from "./InputNumber.vue";

import FormPago from "@/components/PanelAvisosFormPago.vue";

export default defineComponent({
  props: {
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    editId: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    Datepicker,
    InputUsd,
    ButtonBlue,
    CSelect,
    FormPago,
    InputNumber,
  },
  setup(props, { emit }) {
    const store = useStore();

    let editMode = toRef(props, "editMode");
    let editId = toRef(props, "editId");
    let disabled = toRef(props, "disabled");
    let monedaActiva = ref("");
    let loadingRecibo = ref(false);

    let bancos = ref([]);
    let sucursales = reactive({});
    let agencias = ref([]);
    let monedas = ref([]);
    let clients = ref([]);
    let estados = ref([]);
    let fechaHoy = format(new Date(), "dd/MM/yyyy");

    let aviso = reactive({
      cliente: "",
      fechaPago: new Date(),
      comentarios: "",
      estado: "",
      comprobantes: [],
      cheque: [],
      giro: [],
      efectivo: [],
      tarjeta: [],
      tipoCambio: 0,
      totalPagado: 0,
      access_token: store.getters.token,
    });

    const formasDePago = ref([
      {
        idBack: 0,
        id: "cheque",
        textoBack: "Cheque",
        texto: "Cheque",
      },
      {
        idBack: 0,
        id: "giro",
        textoBack: "Giro Bancario",
        texto: "Giro Bancario",
      },
      {
        idBack: 0,
        id: "tarjeta",
        textoBack: "Tarjeta",
        texto: "Tarjeta",
      },
      {
        idBack: 0,
        id: "efectivo",
        textoBack: "Efectivo",
        texto: "Efectivo",
      },
    ]);

    // Bancos, Sucursales, $ a USD, formasDePago
    const getPaymentMethods = () => {
      return axios.get(apiPaymentMethods()).then((res) => {
        aviso.tipoCambio = parseFloat(res.data.usd_rate);

        let dataBancos = res.data.banks;
        let { bancos: fBancos, sucursales: fSucursales } =
          formatBancos(dataBancos);
        bancos.value.push(...fBancos);
        Object.assign(sucursales, fSucursales);

        // Linkeo id de back con objeto de front
        let dataFormasPago = res.data.payment_methods;
        Object.keys(dataFormasPago).map((key) => {
          let strForma = dataFormasPago[key];
          formasDePago.value.find((f) => f.textoBack === strForma).idBack = key;
        });

        let dataCurrency = res.data.corrency_types.map((item) => {
          return {
            value: item.id.toString(),
            text: item.name,
            rate: item.rate,
          };
        });
        monedas.value.push(...dataCurrency);
        monedaActiva.value = monedas.value[0].value;
      });
    };

    // Clientes
    const getFilters = () => {
      axios.get(apiGetFilters()).then((res) => {
        let dataClients = Object.entries(res.data["clients"]);
        clients.value = dataClients
          .map((item, index) => {
            return {
              index: index,
              value: item[0],
              text: `${item[1].trim()} (${item[0]})`,
            };
          })
          .sort((a, b) => {
            return a.text.trim().toUpperCase() > b.text.trim().toUpperCase()
              ? 1
              : -1;
          });

        let dataStatuses = Object.entries(res.data["statuses"]);
        estados.value = dataStatuses.map((item, index) => {
          return {
            index: index,
            value: item[0],
            text: item[1],
          };
        });
      });
    };

    // Agencias
    // No se usa, queda comentado por si vuelve a futuro
    const getAgencias = () => {
      // axios.get(apiGetAgencias()).then((res) => {
      //   res = res.data.map((item) => {
      //     return {
      //       value: item.agencia_id,
      //       text: item.agencia,
      //     };
      //   });
      //   agencias.value.push(...res);
      // });
    };

    const init = async () => {
      getAgencias();
      // Await necesario para linkear los ids de datos a editar
      await Promise.all([getPaymentMethods(), getFilters()]);
      if (editMode.value) {
        getClientPayments();
      }
    };
    init();

    // Comprobantes a imputar
    let comprobantes = ref([]);
    let comprobantesSelected = ref([]);
    let loadingComprobantes = ref(false);
    const getDocuments = () => {
      // Get de comprobantes
      let url = "";
      if (editMode.value) {
        url = apiGetDocumentsFromForm(editId.value);
      } else {
        // Reset de variables por cada cambio
        comprobantes.value.splice(0);
        comprobantesSelected.value.splice(0);
        loadingComprobantes.value = true;
        url = apiGetDocuments(aviso.cliente);
      }

      axios
        .get(url)
        .then((res) => {
          res.data = res.data.map((item, index) => {
            return {
              id: index,
              documentId: item.document_id,
              tipoMoneda: item.currency_id,
              text: item.text,
              value: parseFloat(
                item.amount.replace("$", "").replace(".", "").replace(",", ".")
              ),
              url: urlSige(item.sige_params),
            };
          });
          comprobantes.value.push(...res.data);
          loadingComprobantes.value = false;
        })
        .catch(() => {
          loadingComprobantes.value = false;
        });
    };

    const comprobantesComputed = computed(() => {
      return comprobantes.value.filter((comprobante) => {
        // Si no viene el tipo de moneda del comprobante, se pone usd
        let tipoMoneda = comprobante.tipoMoneda || comprobante.tipoMoneda === 0 ? comprobante.tipoMoneda : monedas.value[0].value
        let monedaComprobante = tipoMoneda;

        return monedaComprobante == monedaActiva.value;
      });
    });
    const changeMonedaActiva = (selected) => {
      comprobantesSelected.value.splice(0);
      monedaActiva.value = selected;
    };

    const urlSige = (item) => {
      // let site = process.env.VUE_APP_FELI_SITE;
      let DocFEDocCod = item.DocFEDocCod;
      let DocCabSerie = item.DocCabSerie;
      let DocCabNro = item.DocCabNro;
      return `${DocFEDocCod}/${DocCabSerie}/${DocCabNro}`;
      // return `DocFEDocCod=${DocFEDocCod}&DocCabSerie=${DocCabSerie}&DocCabNro=${DocCabNro}`;
    };
    // Por cada cambio de cliente, vuelve a pedir los comprobantes
    watch(
      () => aviso.cliente,
      () => {
        if (!editMode.value) {
          getDocuments();
        }
      }
    );

    // Init de data para Editar
    const getClientPayments = () => {
      axios
        .get(apiClientPayments(editId.value))
        .then((res) => {
          let data = res.data;
          let avisoEdit = {
            cliente: null, //Para que no permita cambiarlo
            clienteStr: data.client,
            fechaPago: data.payment_date
              ? parse(data.payment_date, "yyyy-MM-dd", new Date())
              : new Date(),
            estado: data.status?.toString() || "",
            comentarios: data.comments,
            tipoCambio: data.usd_rate
          };
          Object.assign(aviso, avisoEdit);

          comprobantesSelected.value.push(...data.invoices);

          // Crea los elementos de pago
          data.payments.forEach((payment) => {
            let idTipo = formasDePago.value.find(
              (f) => f.idBack == payment.tipo
            ).id;
            addPago(idTipo, { ...payment });
          });

          getDocuments();
        })
        .catch((err) => {
          showError(err);
        });
    };

    // Creación de nuevo elemento de pago
    const addPago = (tipo, precarga = {}) => {
      aviso[tipo].push({
        tipo: tipo,
        idTipo: formasDePago.value.find((f) => f.id === tipo).idBack,
        id: precarga.id || "",
        importe: precarga.importe || 0,
        moneda:
          precarga.moneda !== undefined
            ? precarga.moneda.toString()
            : monedas.value.find((m) => m.text.toLowerCase() == "usd").value,
        numero: precarga.nro_cheque ? parseInt(precarga.nro_cheque) : 0,
        emision: precarga.fecha_emision
          ? stringToDate(precarga.fecha_emision)
          : "",
        pago: precarga.fecha_pago ? stringToDate(precarga.fecha_pago) : "",
        agencia: precarga.agencia_id?.toString() || "",
        banco: precarga.banco_id
          ? getBancoId(sucursales, precarga.banco_id)
          : "",
        sucursal: precarga.banco_id?.toString() || "",
        file: precarga.file_id || "",
      });
    };

    // Borrar elemento de pago
    const removeIndex = (tipo, index) => {
      aviso[tipo].splice(index, 1);
    };

    const totalComprobantes = computed(() => {
      return roundToTwo(
        comprobantes.value
          .filter(
            (c) => comprobantesSelected.value.indexOf(c.documentId) !== -1
          )
          .reduce((acum, comprobante) => {
            let moneda = monedas.value.find(
              (m) => m.value == monedaActiva.value
            );
            let tipoCambio =
              moneda.text.toLowerCase() == "usd" ? 1 : aviso.tipoCambio;
            let valor = convertirMoneda(comprobante.value, tipoCambio);
            return (acum += valor);
          }, 0)
      );
    });

    const totalPagado = computed(() => {
      let acum = 0;
      formasDePago.value.forEach((fp) => {
        aviso[fp.id].forEach((item) => {
          let moneda = monedas.value.find((m) => m.value == item.moneda);
          let tipoCambio =
            moneda.text.toLowerCase() == "usd" ? 1 : aviso.tipoCambio;
          let valor = convertirMoneda(item.importe, tipoCambio);
          return (acum += valor);
        });
      });
      return roundToTwo(acum);
    });
    const roundToTwo = (num) => {
      return +(Math.round(num + "e+2") + "e-2");
    };

    const convertirMoneda = (importe, rate) => {
      return roundToTwo(importe / parseFloat(rate));
    };

    const importeRestante = computed(() => {
      return roundToTwo(totalPagado.value - totalComprobantes.value);
    });

    const descargarComprobante = (url) => {
      axios
        .get(apiDescargarSige(url), { responseType: "blob" })
        .then((response) => {
          const a = document.getElementById("descargaComprobante");
          a.href = URL.createObjectURL(response.data);
          a.download = "comprobante-" + url + ".pdf";
          a.click();
        });
    };

    const enviar = () => {
      aviso.comprobantes = comprobantesSelected.value;
      aviso.totalPagado = totalPagado;
      emit("enviar", { ...aviso });
    };

    const goBack = () => {
      if (!disabled.value) {
        router.go(-1);
      }
    };

    return {
      aviso,
      fechaHoy,
      addPago,
      removeIndex,
      comprobantes,
      totalComprobantes,
      totalPagado,
      importeRestante,
      formasDePago,
      enviar,
      comprobantesSelected,
      bancos,
      sucursales,
      monedas,
      clients,
      agencias,
      loadingComprobantes,
      estados,
      goBack,
      parseISO,
      format,
      monedaActiva,
      comprobantesComputed,
      changeMonedaActiva,
      loadingRecibo,
      descargarComprobante,
    };
  },
});
</script>