<template>
  <div class="relative">
    <span class="absolute left-1">USD</span>
    <input type="text" required :value="value" @input="$emit('update:value',$event.target.value)" disabled class="pl-10 w-full bg-transparent" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        value:{
            type: Number,
            required: true
        }
    },
    setup() {
        return { }
    },
})
</script>
